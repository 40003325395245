@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'creatoDisplay';
  src: url('./assets//fonts/creato-display.thin.otf') format('truetype');

}

@font-face {
  font-family: 'creatoDisplayMedium';
  src: url('./assets//fonts/creato-display.medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'creatoDisplayBold';
  src: url('./assets//fonts/creato-display.bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'creatoDisplayRegular';
  src: url('./assets//fonts/creato-display.bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'creatoDisplayLight';
  src: url('./assets//fonts/creato-display.light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'creatoDisplayBlack';
  src: url('./assets//fonts/creato-display.black.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'creatoDisplay', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
  height: 8px;
  /* Height of the scrollbar (for horizontal scroll) */
}

/* Scrollbar track (background) */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track */
  border-radius: 10px;
  /* Rounded corners */
}

/* Scrollbar thumb (scrolling element) */
*::-webkit-scrollbar-thumb {
  background-color: #faa71786;
  /* Color of the thumb */
  border-radius: 10px;
  /* Rounded corners */
  border: 3px solid #f1f1f1;
  /* Adds padding around the thumb */
}

/* Scrollbar thumb hover effect */
*::-webkit-scrollbar-thumb:hover {
  background-color: #E4A231;
  /* Darker color on hover */
}

/* Scrollbar corner (bottom-right corner where vertical and horizontal scrollbars meet) */
*::-webkit-scrollbar-corner {
  background: #f1f1f1;
  /* Color of the corner */
}

/* Scrollbar button up/left */
*::-webkit-scrollbar-button {
  display: none;
  /* Hides scrollbar buttons */
}

.creato-f-b {
  font-family: 'creatoDisplayBold';
}

.creato-f-black {
  font-family: 'creatoDisplayBlack';
}

.creato-f-m {
  font-family: 'creatoDisplayMedium';
}

.creato-f-r {
  font-family: 'creatoDisplayRegular';
}

.creato-f-l {
  font-family: 'creatoDisplayLight';
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.segoeui {

  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

}

.text-rich-content {
  font-family: 'Poppins';

  h3 {
    font-size: 28px;
    /* Font size for h3 */
    font-weight: bold;
    font-family: 'Poppins';
    /* Bold font weight */
    color: #2c3e50;
    /* Dark color for better readability */
    margin-bottom: 1rem;
    /* Space below h3 */
  }

  h4 {
    font-size: 24px;
    font-family: 'Poppins';

    /* Font size for h4 */
    font-weight: semi-bold;
    /* Semi-bold font weight */
    color: #34495e;
    /* Slightly lighter color for h4 */
    margin-bottom: 0.75rem;
    /* Space below h4 */
  }

  p {
    font-size: 16px;
    /* Font size for paragraphs */
    line-height: 1.6;
    /* Line height for better readability */
    color: #555;
    /* Medium grey color for paragraph text */
    margin-bottom: 1rem;
    /* Space below paragraphs */
  }

  ul {
    padding-left: 20px;
    /* Indent for unordered lists */
    margin-bottom: 1rem;
    /* Space below the list */
  }

  li {
    margin-bottom: 0.5rem;
    /* Space below list items */
    font-size: 16px;
    /* Font size for list items */
    color: #555;
    /* Color for list items */
  }

  li p {
    margin: 0;
    /* Remove margin from paragraph inside li */
    color: #555;
    /* Keep paragraph color same as li */
  }

  strong {
    color: #e67e22;
    /* Color for strong text */
    font-weight: bold;
    /* Bold font weight for strong text */
  }
}

.newBuilding ul{
list-style: disc;
margin: 10px;
padding-left: 30px;
}

.newBuilding h3{
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1.125rem;
  font-weight: 600;
}
.google-login-button {}