@import "~react-image-gallery/styles/css/image-gallery.css";

body,
html {
    overflow-x: hidden;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}






.image-gallery-slide img {
    border: none; 
    outline: none;
    transition: border 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for border and shadow */
}
.image-gallery-slide img:focus {
    border: none; 
    outline: none;
    transition: border 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for border and shadow */
}

.image-gallery-thumbnail.active {
    border-color: #E4A231; /* Highlight active thumbnail */
    box-shadow: 0 0 5px #E4A231;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}
.image-gallery-thumbnail:focus{
    border: none;
    outline: none;
}

.image-gallery-thumbnail:hover {
    border-color: #E4A231; /* Highlight active thumbnail */
    box-shadow: 0 0 5px #E4A231;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}
.image-gallery-icon:focus{
    border: none;
    outline: none;
}
.image-gallery-icon:hover {
    color: #E4A231; /* Change hover color of navigation arrows */
}










.lp-pagination.pagination-container ul,.pagination-container ul {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0
}

.lp-pagination.pagination-container ul li,.pagination-container ul li {
    margin: 0 var(--lp-grid-gap-xxsmall);
    position: relative
}

.lp-pagination.pagination-container ul li a,.pagination-container ul li a {
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    color: var(--lp-color-primary-5);
    cursor: pointer;
    display: inline-flex;
    height: var(--btn-height);
    justify-content: center;
    outline: none;
    padding: 0;
    text-decoration: none;
    transition: all .3s;
    width: var(--btn-height)
}

.lp-pagination.pagination-container ul li a:after,.pagination-container ul li a:after {
    display: none
}

.lp-pagination.pagination-container ul li a:focus-visible,.pagination-container ul li a:focus-visible {
    background-color: var(--lp-color-primary-accent-focused);
    border-color: transparent;
    box-shadow: 0 2px 8px 0 rgba(27,75,236,.64);
    color: var(--lp-color-primary-1)
}

.lp-pagination.pagination-container ul li a:disabled,.pagination-container ul li a:disabled {
    background-color: var(--lp-color-primary-accent-disabled);
    border-color: transparent;
    color: var(--lp-color-primary-text-disabled)
}

.lp-pagination.pagination-container ul li a.active,.lp-pagination.pagination-container ul li a.active:hover,.lp-pagination.pagination-container ul li a:active,.pagination-container ul li a.active,.pagination-container ul li a.active:hover,.pagination-container ul li a:active {
    background-color: var(--lp-color-primary-accent-active);
    border-color: transparent;
    color: var(--lp-color-primary-1)
}

@media(hover: hover)and (pointer:fine) {
    .lp-pagination.pagination-container ul li a:hover,.pagination-container ul li a:hover {
        background-color:transparent;
        border-color: var(--lp-color-primary-5-300);
        color: var(--lp-color-primary-5)
    }
}

.lp-pagination.pagination-container ul li.active a,.pagination-container ul li.active a {
    background-color: var(--lp-color-primary-accent);
    border-color: transparent;
    color: var(--lp-color-primary-1);
    cursor: default;
    pointer-events: none;
    position: relative
}

.lp-pagination.pagination-container ul li.paginationjs-next a,.lp-pagination.pagination-container ul li.paginationjs-prev a,.pagination-container ul li.paginationjs-next a,.pagination-container ul li.paginationjs-prev a {
    border-color: var(--lp-color-primary-5-300);
    font-size: 0;
    line-height: 1;
    text-decoration: none
}

.lp-pagination.pagination-container ul li.paginationjs-next a:after,.lp-pagination.pagination-container ul li.paginationjs-prev a:after,.pagination-container ul li.paginationjs-next a:after,.pagination-container ul li.paginationjs-prev a:after {
    height: var(--icon-size);
    width: var(--icon-size)
}

.lp-pagination.pagination-container ul li.paginationjs-next a:after,.lp-pagination.pagination-container ul li.paginationjs-prev a:after,.pagination-container ul li.paginationjs-next a:after,.pagination-container ul li.paginationjs-prev a:after {
    background: currentColor
}

.lp-pagination.pagination-container ul li.paginationjs-next a:after,.lp-pagination.pagination-container ul li.paginationjs-prev a:after,.pagination-container ul li.paginationjs-next a:after,.pagination-container ul li.paginationjs-prev a:after {
    content: "";
    display: inline-block;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    vertical-align: middle
}

.lp-pagination.pagination-container ul li.paginationjs-next a:after,.lp-pagination.pagination-container ul li.paginationjs-prev a:after,.pagination-container ul li.paginationjs-next a:after,.pagination-container ul li.paginationjs-prev a:after {
    -webkit-mask-image: var(--lp-icon-arrow);
    mask-image: var(--lp-icon-arrow)
}

.lp-pagination.pagination-container ul li.paginationjs-next.disabled,.lp-pagination.pagination-container ul li.paginationjs-prev.disabled,.pagination-container ul li.paginationjs-next.disabled,.pagination-container ul li.paginationjs-prev.disabled {
    pointer-events: none
}

.lp-pagination.pagination-container ul li.paginationjs-page:first-child,.pagination-container ul li.paginationjs-page:first-child {
    margin-left: 0
}

.lp-pagination.pagination-container ul li.paginationjs-page:last-child,.pagination-container ul li.paginationjs-page:last-child {
    margin-right: 0
}

.lp-pagination.pagination-container ul li.paginationjs-prev a:after,.pagination-container ul li.paginationjs-prev a:after {
    transform: scaleX(-1)
}

.lp-pagination.pagination-container.lp-pagination--light ul li.paginationjs-next a,.lp-pagination.pagination-container.lp-pagination--light ul li.paginationjs-prev a,.pagination-container.lp-pagination--light ul li.paginationjs-next a,.pagination-container.lp-pagination--light ul li.paginationjs-prev a {
    background-color: var(--lp-color-error)
}

.lp-pagination.pagination-container.lp-pagination--light ul li a,.pagination-container.lp-pagination--light ul li a {
    border-color: var(--lp-color-primary-g-3)
}

.lp-pagination.pagination-container.lp-pagination--light ul li a:focus-visible,.pagination-container.lp-pagination--light ul li a:focus-visible {
    background-color: var(--lp-color-primary-accent-focused);
    border-color: transparent;
    box-shadow: 0 2px 8px 0 rgba(27,75,236,.64);
    color: var(--lp-color-primary-1)
}

.lp-pagination.pagination-container.lp-pagination--light ul li a:disabled,.pagination-container.lp-pagination--light ul li a:disabled {
    background-color: var(--lp-color-primary-accent-disabled);
    border-color: transparent;
    color: var(--lp-color-primary-text-disabled)
}

.lp-pagination.pagination-container.lp-pagination--light ul li a.active,.lp-pagination.pagination-container.lp-pagination--light ul li a.active:hover,.lp-pagination.pagination-container.lp-pagination--light ul li a:active,.pagination-container.lp-pagination--light ul li a.active,.pagination-container.lp-pagination--light ul li a.active:hover,.pagination-container.lp-pagination--light ul li a:active {
    background-color: var(--lp-color-primary-accent-active);
    border-color: transparent;
    color: var(--lp-color-primary-1)
}

@media(hover: hover)and (pointer:fine) {
    .lp-pagination.pagination-container.lp-pagination--light ul li a:hover,.pagination-container.lp-pagination--light ul li a:hover {
        background-color:var(--lp-color-primary-accent-hover);
        border-color: transparent;
        color: var(--lp-color-primary-1)
    }
}

.list-pagination {
    display: none;
    gap: 1rem;
    justify-content: center
}

.list-pagination.visible {
    display: flex
}

.list-pagination .js-next,.list-pagination .js-prev {
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    border-color: var(--lp-color-primary-5-300);
    border-radius: var(--border-radius);
    color: var(--lp-color-primary-5);
    cursor: pointer;
    display: inline-flex;
    font-size: 0;
    height: var(--btn-height);
    justify-content: center;
    line-height: 1;
    outline: none;
    padding: 0;
    text-decoration: none;
    transition: all .3s;
    width: var(--btn-height)
}

.list-pagination .js-next:after,.list-pagination .js-prev:after {
    display: none
}

.list-pagination .js-next:focus-visible,.list-pagination .js-prev:focus-visible {
    background-color: var(--lp-color-primary-accent-focused);
    border-color: transparent;
    box-shadow: 0 2px 8px 0 rgba(27,75,236,.64);
    color: var(--lp-color-primary-1)
}

.list-pagination .js-next:disabled,.list-pagination .js-prev:disabled {
    background-color: var(--lp-color-primary-accent-disabled);
    border-color: transparent;
    color: var(--lp-color-primary-text-disabled)
}

.list-pagination .js-next.active,.list-pagination .js-next.active:hover,.list-pagination .js-next:active,.list-pagination .js-prev.active,.list-pagination .js-prev.active:hover,.list-pagination .js-prev:active {
    background-color: var(--lp-color-primary-accent-active);
    border-color: transparent;
    color: var(--lp-color-primary-1)
}

@media(hover: hover)and (pointer:fine) {
    .list-pagination .js-next:hover,.list-pagination .js-prev:hover {
        background-color:transparent;
        border-color: var(--lp-color-primary-5-300);
        color: var(--lp-color-primary-5)
    }
}

.list-pagination .js-next:after,.list-pagination .js-prev:after {
    height: var(--icon-size);
    width: var(--icon-size)
}

.list-pagination .js-next:after,.list-pagination .js-prev:after {
    background: currentColor
}

.list-pagination .js-next:after,.list-pagination .js-prev:after {
    content: "";
    display: inline-block;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    vertical-align: middle
}

.list-pagination .js-next:after,.list-pagination .js-prev:after {
    -webkit-mask-image: var(--lp-icon-arrow);
    mask-image: var(--lp-icon-arrow)
}

.list-pagination .js-next.disabled,.list-pagination .js-prev.disabled {
    pointer-events: none
}

.list-pagination .js-prev:after {
    transform: scaleX(-1)
}

.list-pagination ul {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0
}

.list-pagination ul li {
    margin: 0 var(--lp-grid-gap-xxsmall);
    position: relative
}

.list-pagination ul li a {
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    color: var(--lp-color-primary-5);
    cursor: pointer;
    display: inline-flex;
    height: var(--btn-height);
    justify-content: center;
    outline: none;
    padding: 0;
    text-decoration: none;
    transition: all .3s;
    width: var(--btn-height)
}

.list-pagination ul li a:after {
    display: none
}

.list-pagination ul li a:focus-visible {
    background-color: var(--lp-color-primary-accent-focused);
    border-color: transparent;
    box-shadow: 0 2px 8px 0 rgba(27,75,236,.64);
    color: var(--lp-color-primary-1)
}

.list-pagination ul li a:disabled {
    background-color: var(--lp-color-primary-accent-disabled);
    border-color: transparent;
    color: var(--lp-color-primary-text-disabled)
}

.list-pagination ul li a.active,.list-pagination ul li a.active:hover,.list-pagination ul li a:active {
    background-color: var(--lp-color-primary-accent-active);
    border-color: transparent;
    color: var(--lp-color-primary-1)
}

@media(hover: hover)and (pointer:fine) {
    .list-pagination ul li a:hover {
        background-color:transparent;
        border-color: var(--lp-color-primary-5-300);
        color: var(--lp-color-primary-5)
    }
}

.list-pagination ul li.active a {
    background-color: var(--lp-color-primary-accent);
    border-color: transparent;
    color: var(--lp-color-primary-1);
    cursor: default;
    pointer-events: none;
    position: relative
}

.list-pagination ul li:first-child {
    margin-left: 0
}

.list-pagination ul li:last-child {
    margin-right: 0
}































.hero_section {
    background-image: url('./assets//images/hero_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    background-attachment: fixed;
    height: 100%;
}

.gradient-background {
    background: linear-gradient(to top,
            rgba(0, 0, 0, 0.9),
            /* Dark color with 50% opacity at the bottom */
            rgba(0, 0, 0, 0)
            /* Transparent color at the top */
        );
}

/* .nav-item::after { */

/* content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #E4A231;
    transition: width .4s;
}

.nav-item:hover::after {
    width: 100%;

} */
.nav-item {
    position: relative;
    /* display: inline-block; */
    cursor: pointer;
}

.nav-item::before {
    content: '';
    position: absolute;
    bottom: -.2rem;
    left: 0rem;
    right: 0;
    border-bottom: 3px solid #e4a231;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.nav-item.nav-white::before {
    border-bottom: 3px solid white;

}

.nav-item:hover::before {
    transform-origin: 0% 50%;
    transform: scaleX(1);
}

.nav-active::before {
    transform-origin: 0% 50%;
    transform: scaleX(1);
}


input::placeholder {
    color: #191818;
    opacity: 0.80;
    font-weight: 100;
}

.btn-primary {
    position: relative;
    transition: color 0.3s ease;
    overflow: hidden;
    z-index: 2;
}

.btn-primary::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #022541;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform 0.3s ease;
    z-index: -1;
}

.btn-primary.btn2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #e4a231 !important;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform 0.3s ease;
    z-index: -1;
}

.btn-primary:hover::before {
    transform-origin: 0% 50%;
    transform: scaleX(1);
}

.btn-primary:hover {
    color: #fff;
    /* Optional: Change text color on hover */
}

.uploader-properties .ant-upload-drag {

    border: none !important;
    background: transparent !important;
}

.dark-bottom-overlay:after {
    content: "";
    position: absolute;
    z-index: 3;
    height: 100%;
    width: 100%;
    bottom: 0;
    background: linear-gradient(180deg, transparent 0, transparent 30%, #000);
    opacity: 0.8;
}

.sec-3-arrow>svg {
    transition: margin 0.4s ease-out;

}

.sec-3-arrow:hover>svg {
    margin: -17px -17px auto auto;
}

.sec4_bg {
    background-image: url('./assets/images/sec4_bg.png');
    background-repeat: no-repeat;
    overflow: hidden;
}

.bg-size-100 {
    background-size: 100% !important;

}

.sec_5_bg {

    /* background-image: url('./assets//images/Rectangle\ 16.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0% 14% 20% 17% / 0% 15% 0% 18%;
}

.contact_right_bg {
    background-image: url('./assets/images/contatct_bg_left.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.custom-ant-table .ant-table-thead > tr > th {
    background-color: #e4a231 !important;
    color: #fff; /* Optional: To ensure text is readable */
  }
  
  /* For the hover effect on rows */
  /* .custom-ant-table .ant-table-tbody > tr:hover {
    background-color: #e4a231 !important;
  } */
  .ant-table-cell-row-hover {
    background-color: #e4a231 !important;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: #fff;
  }

@media (min-width: 768px) {
    .contact_right_bg {
        background-image: url('./assets/images/contact_bg_right.png') !important;
        background-size: contain !important;
        background-repeat: no-repeat;
        background-position: left;
    }

}

.chat-top-grad {
    height: 188px;
    border-radius: 26px;
    background: #e4a231;
    background: linear-gradient(120deg,
            #e4a231 0.00%,
            #ffffff 100.00%);
}

.cb-button .cbb-circle {
    background-color: rgba(186, 152, 0, 0);
    border: .0625rem solid #e4a231c5;
    opacity: .5;
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    width: 10rem;
    height: 10rem;
    animation: animation1 2.2s ease-in-out 0s infinite normal none running;
    border-radius: 100% !important;
}

.cb-button .cbb-circle-fill {
    background-color: rgba(255, 252, 80, 0.3);
    border: .0625rem solid #e4a231;
    opacity: 1;
    position: absolute;
    top: 3.125rem;
    left: 3.125rem;
    width: 6.25rem;
    height: 6.25rem;
    animation: animation2 1.7s ease-in-out 0s infinite normal none running;
    border-radius: 100% !important;
}

.cb-button .cbb-circle-img {
    background-color: #e4a231;
    border: .0625rem solid #e4a231;
    position: absolute;
    top: 4.375rem;
    left: 4.375rem;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 100% !important;
}

.black_white_effect {
    filter: unset;
    /* IE5+ */
    -webkit-filter: unset;
    /* Webkit Nightlies & Chrome Canary */
    -webkit-transition: all .5s ease-in-out;
}

.black_white_effect:hover {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -webkit-transform: scale(1.01);
}

.black_white_effect2 div img {
    filter: unset;
    /* IE5+ */
    -webkit-filter: unset;
    /* Webkit Nightlies & Chrome Canary */
    -webkit-transition: all .4s ease-in-out;
}

.black_white_effect2 div img:hover {
    filter: grayscale(0.8);
    -webkit-filter: grayscale(0.8);
    -webkit-transform: scale(1.1);
}

.black_white_effect3 div img:hover {
    filter: grayscale(0.8);
    -webkit-filter: grayscale(0.8);
    -webkit-transition: all .4s ease-in-out;

}

.ring-animate {
    animation: ring 2s ease 0s infinite normal none running;
}

@keyframes ring {
    0% {
        transform: rotate(-15deg);
    }

    2% {
        transform: rotate(15deg);
    }

    4% {
        transform: rotate(-18deg);
    }

    6% {
        transform: rotate(18deg);
    }

    8% {
        transform: rotate(-22deg);
    }

    10% {
        transform: rotate(22deg);
    }

    12% {
        transform: rotate(-18deg);
    }

    14% {
        transform: rotate(18deg);
    }

    16% {
        transform: rotate(-12deg);
    }

    18% {
        transform: rotate(12deg);
    }

    20%,
    100% {
        transform: rotate(0deg);
    }
}

@keyframes animation1 {
    0% {
        opacity: .1;
        transform: scale(.4);
    }

    70% {
        opacity: .6;
        transform: scale(1);
    }

    100% {
        opacity: .3;
        transform: scale(.5);
    }
}

@keyframes animation2 {
    0% {
        opacity: .3;
        transform: scale(.6);
    }

    70% {
        opacity: .8;
        transform: scale(1);
    }

    100% {
        opacity: .1;
        transform: scale(.7);
    }
}

.shadow_c_1 {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.sell-property-bg {
    background-image: url(./assets/images/bg-sell-property-form.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
}